import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, SecondaryCallout, KMCurve, ComparisonTable, NCCNcallout, ReferencesBlock } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import RCC from '../../components/StudyDesign/__study-design/rcc'
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-426 - Clinical Trial Results | HCP`,
    keywords: `keynote 426, keynote-426`,
    description: `Health care professionals may find clinical trial results from KEYNOTE-426 in patients with advanced renal cell carcinoma (RCC).`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/advanced-renal-cell-carcinoma/","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠426","description":"Clinical Findings from KEYNOTE⁠-⁠426","trialDesign":"Phase 3, multicenter, open-label, randomized trial vs sunitinib"}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/advanced-renal-cell-carcinoma/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 8;
// const jobCode = jobCodes[33].jobCode;
const jobCode = "US-RCC-01076 02/25";


const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: 'Adjuvant RCC', url: '/efficacy/adjuvant-renal-cell-carcinoma/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/advanced-renal-cell-carcinoma/' },
                { text: 'Dosing', url: '/dosing/options/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Overall Survival: Initial and Final Analysis From KEYNOTE&#8288;-&#8288;426' }, 
    { label: 'PFS and ORR: Initial and Final Analysis From KEYNOTE&#8288;-&#8288;426' }, 
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;426' },
    { label: 'NCCN Clinical Practice Guidelines In Oncology (NCCN Guidelines®) for Kidney Cancer' }
]
// const medianPatientSecondaryCalloutFootnotes = [
//     {
//         label: "a.",
//         text: "Range: 0.1–22.0 months."
//     },
// ]

const InitialAnalysisKMCCurveFootnotes = [
    {
        label: "b.",
        text: "Based on the stratified Cox proportional hazard model."
    },
    {
        label: "c.",
        text: "Based on stratified log-rank test."
    },
    {
        label: "d.",
        text: "<i>P</i> value (one-sided) is compared with the allocated alpha of 0.0001 for this interim analysis (with 39% of the planned number of events for final analysis)."
    },
];

const OScomparisonTableFootnotes = [
    {
        label: "a.",
        text: "Based on the stratified Cox proportional hazard model."
    },
    {
        label: "b.",
        text: "Based on stratified log-rank test."
    },
    {
        label: "c.",
        text: "<i>P</i> value (one-sided) is compared with the allocated alpha of 0.0001 for this interim analysis (with 39% of the planned number of events for final analysis)."
    },
];

const PFScomparisonTableFootnotes = [
    {
        label: "d.",
        text: "Based on the stratified Cox proportional hazard model."
    },
    {
        label: "e.",
        text: "Based on stratified log-rank test."
    },
    {
        label: "f.",
        text: "<i>P</i> value (one-sided) is compared with the allocated alpha of 0.0013 for this interim analysis (with 81% of the planned number of events for final analysis)."
    },
];

const ORRcomparisonTableFootnotes = [
    {
        label: "g.",
        text: "Response: Best objective response rate as confirmed complete response or partial response."
    },
    {
        label: "h.",
        text: "Based on Miettinen and Nurminen method stratified by IMDC risk group and geographic region."
    },
];

// const finalPrespecifiedAnalysisSecondaryCalloutFootnotes = [
//     {
//         label: "m.",
//         text: "Range: 35.6–50.6 months."
//     }
// ]

const HRCIcomparisonTableDefinitions = '<p>HR = hazard ratio; CI = confidence interval.</p>';

const OSInitialTableDefinitions = '<p>HR = hazard ratio; CI = confidence interval; IMDC = International Metastatic RCC Database Consortium.</p>';


const Category1NCCNdefinitions = `
    <p>NCCN makes no warranties of any kind whatsoever regarding their content, use, or application and disclaims any responsibility for their application or use in any way.</p>
    <p>Category 1: Based upon high-level evidence (≥1 randomized phase 3 trials or high-quality, robust meta-analyses), there is uniform NCCN consensus (≥85% support of the Panel) that the intervention is appropriate.<sup>3</sup></p>
    <p>Preferred intervention = Intervention that is based on superior efficacy, safety, and evidence; and, when appropriate, affordability.<sup>3</sup></p>
    <p>1L = First Line; IMDC = International Metastatic RCC Database Consortium; NCCN = National Comprehensive Cancer  Network® (NCCN®).</p>
    `;

const Category1NCCNcalloutData2 = {
    title: 'The NCCN Guidelines for kidney cancer recommend pembrolizumab (KEYTRUDA), in combination with axitinib, as a category 1 and PREFERRED 1L treatment option across all IMDC risk groups for advanced clear cell RCC<sup>3</sup>',
    bodyCopy: `
        <h6>Category 1 and PREFERRED therapy option for patients with advanced clear cell RCC, and intermediate/poor risk</h6>
            <ul><li>Pembrolizumab (KEYTRUDA) in combination with axitinib is a preferred 1L therapy option for patients with advanced clear cell RCC, and intermediate/poor risk.</ul></li>
        <h6>Category 1 and PREFERRED therapy option for patients with advanced clear cell RCC, and favorable risk</h6>
            <ul><li>Pembrolizumab (KEYTRUDA) in combination with axitinib is a preferred 1L therapy option for patients with advanced clear cell RCC, and favorable risk.</ul></li>`,
    showBadge: false,
}

const NCCNcalloutReferences = [
    {
        label: "3.",
        text: `Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Kidney Cancer V.3.2025. © National Comprehensive Cancer Network, Inc. 2025. All rights reserved. Accessed January 13, 2025. To view the most recent and complete version of the guideline, go online to NCCN.org.`
    },
];

const pageReferences = [
    {
        label: "1.",
        text: "Data available on request from Merck, Professional Services-DAP, WP1-27, PO Box 4, West Point, PA 19486-0004. Please specify information package US-RCC-01047."
    },
    {
        label: "2.",
        text: "Rini BI, Plimack ER, Stus V, et al; for the KEYNOTE&#8288;-&#8288;426 investigators. Pembrolizumab plus axitinib versus sunitinib for advanced renal-cell carcinoma. <i>N Engl J Med.</i> 2019;380(12):1116–1127."
    },
    {
        label: "3.",
        text: `Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Kidney Cancer V.3.2025. © National Comprehensive Cancer Network, Inc. 2025. All rights reserved. Accessed January 13, 2025. To view the most recent and complete version of the guideline, go online to NCCN.org.`
    }
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">Initial & Final Prespecified Analyses for KEYTRUDA + axitinib vs sunitinib in the First&#8288;-&#8288;Line Treatment of Patients With Advanced RCC</PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>

                            {/* moved from below */}
                            <KMCurve
                                title="Kaplan-Meier Estimates of OS in KEYNOTE-426 at Final Analysis"
                                image={() => <StaticImage 
                                    src="../../assets/ren-426-km-asco-v-3.png" 
                                    placeholder="blurred" 
                                    alt="Initial and Final Analysis Kaplan-Meier Estimates of OS in Patients With Advanced Renal Cell Carcinoma in KEYNOTE-426" />}
                                    hr={`HR<sup>a</sup>=0.73 (95% CI, 0.60–0.88)`}
                                additionalInformation={[
                                    {
                                        text: `
                                        <p>In an exploratory analysis, the updated analysis of OS in patients with IMDC favorable, intermediate, intermediate/poor, and poor risk demonstrated an HR of 1.17 (95% Cl, 0.76, 1.80), 0.67 (95% Cl, 0.52, 0.86), 0.64 (95% Cl, 0.52, 0.80), and 0.51 (95% Cl, 0.32, 0.81), respectively.`},
                                ]}
                            />

                            <SecondaryCallout 
                                title="In the initial analysis, KEYTRUDA + axitinib achieved superiority across OS vs sunitinib" 
                                alt="KEYTRUDA® (pembrolizumab) and Axitinib Achieved Superiority Across OS, PFS, and ORR vs Sunitinib in KEYNOTE-426"    
                            />

                            <ComparisonTable 
                                title="Overall Survival (OS): Initial Analysis" 
                                footnotes={OScomparisonTableFootnotes}
                                definitions={OSInitialTableDefinitions}
                                data={
                                    [
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA + axitinib (n=432)',
                                            },
                                            {
                                                legendTitle: 'Sunitinib (n=429)',
                                            },

                                        ],
                                        [
                                            {
                                                hrCiP: 'HR<sup>a</sup>=0.53 (95% CI, 0.38–0.74; <i>P</i><sup>b</sup><0.0001<sup>c</sup>)',
                                                centerText: true,
                                                colspan: 2,
                                            }
                                        ],
                                        [
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '14%',
                                                dataSubPoint: '(n=59/432)'
                                            },
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '23%',
                                                dataSubPoint: '(n=97/429)'
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Median OS',
                                                dataPoint: 'Not reached',
                                            },
                                            {
                                                label: 'Median OS',
                                                dataPoint: 'Not reached',
                                            },
                                        ],
                                    ]
                                } 
                            />

                            <ComparisonTable 
                                title="Overall Survival (OS): Final Prespecified Analysis"
                                limitation="<b>LIMITATIONS:</b> This final prespecified exploratory analysis occurred after an interim analysis that demonstrated the superiority of KEYTRUDA + axitinib in overall survival, progression-free survival, and objective response rate compared to sunitinib alone. No formal statistical testing was performed for this analysis, and, therefore, no conclusions can be drawn.<sup>1</sup>"
                                data={
                                    [
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA + axitinib (n=432)',
                                            },
                                            {
                                                legendTitle: 'Sunitinib (n=429)',
                                            },

                                        ],
                                        [
                                            {
                                                hrCiP: 'HR<sup>a</sup>=0.73 (95% CI, 0.60–0.88)',
                                                centerText: true,
                                                colspan: 2,
                                            }
                                        ],
                                        [
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '45%',
                                                dataSubPoint: '(n=193/432)'
                                            },
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '52%',
                                                dataSubPoint: '(n=225/429)'
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Median OS',
                                                dataPoint: '45.7 months',
                                                dataSubPoint: '(95% CI, 43.6, NR)'
                                            },
                                            {
                                                label: 'Median OS',
                                                dataPoint: '40.1 months',
                                                dataSubPoint: '(95% CI, 34.3, 44.2)'
                                            },
                                        ],
                                    ]
                                } 
                            />
                        </PageSection>

                        <PageSection title={anchorLinkModelData[1].label}>

                            <SecondaryCallout
                                title="In the initial analysis, KEYTRUDA + axitinib achieved superiority across PFS and ORR vs sunitinib"
                                alt="In the Final Prespecified Analysis of KEYNOTE-426, the Median Patient Follow-up for KEYTRUDA® (pembrolizumab) and Axitinib Was 42.8 Months"
                            />
                            {/* MOVEd TO NEXT SECTION 31 */}
                            <ComparisonTable 
                                    title="Progression-Free Survival (PFS): Initial Analysis" 
                                    footnotes={PFScomparisonTableFootnotes} 
                                    definitions={HRCIcomparisonTableDefinitions} 
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA + axitinib (n=432)',
                                                },
                                                {
                                                    legendTitle: 'Sunitinib (n=429)',
                                                },
                                            ],
                                            [
                                                {
                                                    hrCiP: 'HR<sup>d</sup>=0.69 (95% CI, 0.56–0.84; <i>P</i><sup>e</sup>=0.0001<sup>f</sup>)',
                                                    centerText: true,
                                                    colspan: 2,
                                                }
                                            ],
                                            [
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '42%',
                                                    dataSubPoint: '(n=183/432)'
                                                },
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '50%',
                                                    dataSubPoint: '(n=213/429)'
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median PFS',
                                                    dataPoint: '15.1 months',
                                                    dataSubPoint: '(95% CI, 12.6–17.7)'
                                                },
                                                {
                                                    label: 'Median PFS',
                                                    dataPoint: '11.0 months',
                                                    dataSubPoint: '(95% CI, 8.7–12.5)'
                                                },
                                            ],
                                        ]
                                    } 
                                />

                                {/* MOVEd TO NEXT SECTION 32 */}
                                <ComparisonTable 
                                    title="Objective Response Rate (ORR)<sup>g</sup>: Initial Analysis" 
                                    footnotes={ORRcomparisonTableFootnotes} 
                                    data={
                                    [
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA + axitinib (n=432)',
                                            },
                                            {
                                                legendTitle: 'Sunitinib (n=429)',
                                            },
                                        ],
                                        [
                                            {
                                                label: 'ORR',
                                                dataPoint: '59%',
                                                dataSubPoint: '(95% CI, 54–64; <i>P</i><sup>h</sup><0.0001)'
                                            },
                                            {
                                                label: 'ORR',
                                                dataPoint: '36%',
                                                dataSubPoint: '(95% CI, 31–40)'
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Complete Response',
                                                dataPoint: '6%',
                                            },
                                            {
                                                label: 'Complete Response',
                                                dataPoint: '2%',
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Partial Response',
                                                dataPoint: '53%',
                                            },
                                            {
                                                label: 'Partial Response',
                                                dataPoint: '34%',
                                            },
                                        ],
                                    ]
                                } /> 

                                <ComparisonTable 
                                    title="Progression-Free Survival (PFS): Final Prespecified Analysis<sup>1</sup>" 
                                    limitation="<b>LIMITATIONS:</b> This final prespecified exploratory analysis occurred after an interim analysis that demonstrated the superiority of KEYTRUDA + axitinib in overall survival, progression-free survival, and objective response rate compared to sunitinib alone. No formal statistical testing was performed for this analysis, and, therefore, no conclusions can be drawn.<sup>1</sup>"
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA + axitinib (n=432)',
                                                },
                                                {
                                                    legendTitle: 'Sunitinib (n=429)',
                                                },

                                            ],
                                            [
                                                {
                                                    hrCiP: 'HR=0.68 (95% CI, 0.58–0.80)',
                                                    centerText: true,
                                                    colspan: 2,
                                                }
                                            ],
                                            [
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '66.2%',
                                                    dataSubPoint: '(n=286/432)'
                                                },
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '70.2%',
                                                    dataSubPoint: '(n=301/429)'
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median PFS',
                                                    dataPoint: '15.7 months',
                                                    dataSubPoint: '(95% CI, 13.6–20.2)'
                                                },
                                                {
                                                    label: 'Median PFS',
                                                    dataPoint: '11.1 months',
                                                    dataSubPoint: '(95% CI, 8.9–12.5)'
                                                },
                                            ],
                                        ]
                                    } 
                                />
                                <ComparisonTable 
                                title="Objective Response Rate (ORR): Final Prespecified Analysis<sup>1</sup>"
                                limitation="<b>LIMITATIONS:</b> This final prespecified exploratory analysis occurred after an interim analysis that demonstrated the superiority of KEYTRUDA + axitinib in overall survival, progression-free survival, and objective response rate compared to sunitinib alone. No formal statistical testing was performed for this analysis, and, therefore, no conclusions can be drawn.<sup>1</sup>"
                                data={
                                    [
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA + axitinib (n=432)',
                                            },
                                            {
                                                legendTitle: 'Sunitinib (n=429)',
                                            },
                                        ],
                                        [
                                            {
                                                label: 'ORR',
                                                dataPoint: '60.4%',
                                                dataSubPoint: '(range: 55.6–65.1)'
                                            },
                                            {
                                                label: 'ORR',
                                                dataPoint: '39.6%',
                                                dataSubPoint: '(range: 35.0–44.4)'
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Complete Response',
                                                dataPoint: '10%',
                                            },
                                            {
                                                label: 'Complete Response',
                                                dataPoint: '3.5%',
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Partial Response',
                                                dataPoint: '50.5%',
                                            },
                                            {
                                                label: 'Partial Response',
                                                dataPoint: '36.1%',
                                            },
                                        ],
                                    ]
                                } />
                        </PageSection>

                        <PageSection  bgColor="cloud" title={anchorLinkModelData[2].label}>
                            <RCC />
                        </PageSection>

                        <PageSection title={anchorLinkModelData[3].label}>
                            <NCCNcallout
                                data={Category1NCCNcalloutData2}
                                definitions={Category1NCCNdefinitions}
                                references={NCCNcalloutReferences}
                            />
                        </PageSection>

                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>

                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout >
    );
};

export default Page;
